import { BASE } from './index';
import axios from './http';

const ranking = {
    //车辆排位图列表
    vehicleLayoutlist: params => axios.post(`${BASE.PRO2}/vehicleLayout/list`, params),
    //车辆排位图列表查看自提点所在位置
    vehicleLayoutsearchlabel: params => axios.post(`${BASE.PRO2}/vehicleLayout/search/label`, params),
    //车辆排位图保存排位数据
    vehicleLayoutstore: params => axios.post(`${BASE.PRO2}/vehicleLayout/store`, params),
    //车辆排位图待排位的城市仓
    vehicleLayoutlogisticslist: params => axios.post(`${BASE.PRO2}/vehicleLayout/logistics/list`, params),
    //车辆排位图待排位的自提点
    vehicleLayoutdeliverylist: params => axios.post(`${BASE.PRO2}/vehicleLayout/delivery/list`, params),
    //车辆排位图排位图清除数据
    vehicleLayoutlistclear: params => axios.post(`${BASE.PRO2}/vehicleLayout/list/clear`, params),

};

export default ranking;
