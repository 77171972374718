import { BASE } from "./index";
import axios from "./http";

/**
 * 获取当前环境的 URL 链接
 * @returns {string}
 */
function getUrlLink() {
  return sessionStorage.getItem("manageIsdentity") === "tdc"
    ? BASE.PRO4
    : BASE.PRO3;
}

/**
 * 城市仓 ｜ 自提点 自定义坑位号列表
 * @param {*} params
 * @returns
 */
export function postTakeOrderPlacenoList(params = {}) {
  const url_link = getUrlLink();
  return axios.post(`${url_link}/take/order/placeno/list`, params);
}

/**
 * 城市仓 ｜ 自提点 用户名备注保存
 * @param {*} params
 * @returns
 */
export function postTakeOrderPlacenoRemarkSave(params = {}) {
  const url_link = getUrlLink();
  return axios.post(`${url_link}/take/order/placeno/remark/save`, params);
}

/**
 * 城市仓 ｜ 自提点 保存坑位号
 * @param {*} params
 * @returns
 */
export function postTakeOrderPlacenoSet(params = {}) {
  const url_link = getUrlLink();
  return axios.post(`${url_link}/take/order/placeno/set`, params);
}

/**
 * 城市仓 ｜ 自提点 重置坑位号
 * @param {*} params
 * @returns
 */
export function postTakeOrderPlacenoReset(params = {}) {
  const url_link = getUrlLink();
  return axios.post(`${url_link}/take/order/placeno/reset`, params);
}

/**
 * 城市仓 ｜ 自提点  导出
 * @param {*} params
 * @returns
 */
export function getTakeOrderPlacenoExport(params = {}) {
  const url_link = getUrlLink();
  return axios({
    method: "GET",
    url: `${url_link}/take/order/placeno/export`,
    responseType: "blob",
    params,
  });
}

/**
 * 配置打印类型自提点列表
 * @param {*} params
 * @returns
 */
export function postTakeOrderPrintTypeList(params = {}) {
  const url_link = getUrlLink();
  return axios.post(`${url_link}/take/order/print/type/list`, params);
}

/**
 * 打印类型保存
 * @param {*} params
 * @returns
 */
export function postTakeOrderPrintTypeSave(params = {}) {
  const url_link = getUrlLink();
  return axios.post(`${url_link}/take/order/print/type/save`, params);
}

/**
 * 分区列表
 * @param {*} params
 * @returns
 */
export function postTakeOrderPlacenoRegion(params = {}) {
  const url_link = getUrlLink();
  return axios.post(`${url_link}/take/order/placeno/region`, params);
}
