import { BASE } from './index';
import axios from './http';
const TDC = {
    // 获取登录人菜单权限
    getRoleMenu: params => axios.get(`${BASE.PRO4}/get/role/menu`, { params }),
    //小程序管理列表
    userlist: params => axios.get(`${BASE.PRO4}/applet/user/list`, { params }),
    //小程序管理添加
    usersave: params => axios.post(`${BASE.PRO4}/applet/user/save`, params),
    // 管理员
    getDeliveryUser: params => axios.get(`${BASE.PRO4}/get/tdc/user`, { params }),
    saveDeliveryUser: params => axios.post(`${BASE.PRO4}/save/tdc/user`, params),
    updateDeliveryUserStatus: params => axios.post(`${BASE.PRO4}/tdc/user/status`, params),
    // 角色
    getRoleList: params => axios.get(`${BASE.PRO4}/get/role/list`, { params }),
    saveRole: params => axios.post(`${BASE.PRO4}/role/save`, params),
    saveRoleMenuIds: params => axios.post(`${BASE.PRO4}/role/menuIds/save`, params),
    getAllMenu: params => axios.get(`${BASE.PRO4}/get/menu/list`, { params }),

    // 接货单列表
    detailbatch: params => axios.post(`${BASE.PRO4}/add/order/detail/batch`, params),
    orderlist: params => axios.post(`${BASE.PRO4}/receive/order/list`, params),
    deliverylist: params => axios.get(`${BASE.PRO4}/delivery/list`, params),
    batchlist: params => axios.post(`${BASE.PRO4}/take/order/batch/list`, params),
    batchmemberlist: params => axios.post(`${BASE.PRO4}/take/order/batch/member/list`, params),
    batchmemberOrder: params => axios.post(`${BASE.PRO4}/take/order/batch/member/order`, params),
    deliveryOrderlist: params => axios.get(`${BASE.PRO4}/order/list`, { params }),
    deliveryOrderInfo: params => axios.post(`${BASE.PRO4}/order/info`, params),
    // 接货单详情
    orderdetail: params => axios.post(`${BASE.PRO4}/receive/order/detail`, params),
    // 接货单详情打印
    orderdetailprint: params => axios.post(`${BASE.PRO4}/receive/order/detail/print`, params),
    // 提货单打印
    orderlistti: params => axios.post(`${BASE.PRO4}/take/order/list`, params),
     // 跟车单打印
     followlist: params => axios.post(`${BASE.PRO4}/qc/list`, params),
      // 跟车单打印
      getCartSheetPrint: params => axios.get(`${BASE.PRO4}/qc/follow_car`, {params}),
      // 跟车单导出
      getTdcCartExport: params => axios.get(`${BASE.PRO4}/qc/lists/export`, {params}),
    // 分货单打印
    goodslistti: params => axios.post(`${BASE.PRO4}/divide/delivery/take/order/list`, params),
    // 提货单打印打印列表批量
    orderlisttiprint: params => axios.get(`${BASE.PRO4}/take/order/print`, {params}),
    // 提货单打印详情
    orderdetailti: params => axios.post(`${BASE.PRO4}/take/order/detail`, params),
    // 提货单核销
    tihuodanhexiao: params => axios.post(`${BASE.PRO4}/tihuodanhexiao`, params),
    // 点击核销
    ordercomplete: params => axios.post(`${BASE.PRO4}/order/complete`, params),
    updatePayPassword: params => axios.post(`${BASE.PRO4}/modify/password`, params),
    aftersaleList: params => axios.post(`${BASE.PRO4}/aftersale/un/list`, params),
  aftersaleEdList: params => axios.post(`${BASE.PRO4}/aftersale/ed/list`, params),
  aftersaleDetail: params => axios.post(`${BASE.PRO4}/aftersale/detail`, params),
  memberList: params => axios.post(`${BASE.PRO4}/member/list`, params),
};

export default TDC;
