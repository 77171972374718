import { BASE } from './index';
import axios from './http';
const Followup = {
    // 回访记录
    member_follow_record_list: params => axios.post(`${BASE.PRO1}/member/follow_record/list`,  params ),
    // 回访记录列表
    follow_record_list: params => axios.post(`${BASE.PRO1}/follow_record/list`,  params ),
    // 回访记录导出
    member_follow_record_listexport: params => axios.post(`${BASE.EXPORT_CENTER}/bi/member/follow_record/export`,  params ),
    // 回访记录列表导出
    follow_record_listexport: params => axios.post(`${BASE.EXPORT_CENTER}/bi/follow_record/export`,  params ),
    // 回访记录城市仓仓
    member_follow_record_list4: params => axios.post(`${BASE.PRO4}/member/follow_record/list`,  params ),
    // 回访记录列表城市仓仓
    follow_record_list4: params => axios.post(`${BASE.PRO4}/follow_record/list`,  params ),
    // 城市仓仓
    logisticsselect: params => axios.post(`${BASE.PRO1}/logistics/select/list`,  params ),

};

export default Followup;
