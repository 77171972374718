
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: sessionStorage.getItem("userInfo") || {},
    token: sessionStorage.getItem("token") || {},
    Refresh: true,
  },
  mutations: {
     //控制界面刷新问题
     setRefresh(state, data) {
      state.Refresh = data
    },
  },
  actions: {
  },
  modules: {
  }
})
