import { BASE } from "./index";
import axios from "./http";

const INDEX = {
  // 获取登录人菜单权限
  getRoleMenu: (params) => axios.get(`${BASE.PRO2}/get/role/menu`, { params }),
  // 管理员
  getLogisticsUser: (params) =>
    axios.get(`${BASE.PRO2}/get/logistics/user`, { params }),
  getControlList: (params) =>
    axios.post(`${BASE.PRO2}/quality/control/list`, params),
  addControlList: (params) =>
    axios.post(`${BASE.PRO2}/add/quality/control`, params),
  updateControlList: (params) =>
    axios.post(`${BASE.PRO2}/modify/quality/control`, params),
  delControlList: (params) =>
    axios.post(`${BASE.PRO2}/delete/quality/control`, params),
  saveLogisticsUser: (params) =>
    axios.post(`${BASE.PRO2}/save/logistics/user`, params),
  updateLogisticsStatus: (params) =>
    axios.post(`${BASE.PRO2}/logistics/user/status`, params),
  // 角色
  getRoleList: (params) => axios.get(`${BASE.PRO2}/get/role/list`, { params }),
  saveRole: (params) => axios.post(`${BASE.PRO2}/role/save`, params),
  saveRoleMenuIds: (params) =>
    axios.post(`${BASE.PRO2}/role/menuIds/save`, params),
  getAllMenu: (params) => axios.get(`${BASE.PRO2}/get/menu/list`, { params }),
  //装车单查询
  entrucklist: (params) =>
    axios.get(`${BASE.PRO2}/order/entruck/list`, { params }),
  startTrainOutStockList: (params) =>
    axios.get(`${BASE.PRO2}/startTrainOutStockList`, { params }),
  qualityControlRecordList: (params) =>
    axios.post(`${BASE.PRO2}/quality/control/qualityControlRecordList`, params),
  entruckOrderlist: (params) => axios.post(`${BASE.PRO2}/order/list`, params),
  entruckOrderPrint: (params) =>
    axios.get(`${BASE.PRO2}/order/entruck/print`, { params }),
  entruckOrderDetail: (params) =>
    axios.post(`${BASE.PRO2}/order/detail`, params),
  startTrainOutStock: (params) =>
    axios.post(`${BASE.PRO2}/startTrainOutStock/add`, params),
  //城市仓
  entruckLogisticslist: (params) =>
    axios.post(`${BASE.PRO2}/logistics/list`, params),
  //自提点
  entruckDeliverylist: (params) =>
    axios.post(`${BASE.PRO2}/delivery/list`, params),
  entruckDeliveryLabel: (params) =>
    axios.post(`${BASE.PRO2}/order/entruck/print/label`, params),
  //装车单详情
  entruckdetail: (params) =>
    axios.get(`${BASE.PRO2}/order/entruck/detail`, { params }),
  //装车单打印获取详情
  entruckprint: (params) =>
    axios.get(`${BASE.PRO2}/order/entruck/print`, { params }),
  roomWindows: (params) => axios.get(`${BASE.PRO2}/windows`, { params }),
  //装车单复核获取详情
  entruckreview: (params) =>
    axios.get(`${BASE.PRO2}/order/entruck/review`, { params }),
  //装车单复合确认
  confirmreview: (params) =>
    axios.post(`${BASE.PRO2}/order/entruck/confirm/review`, params),
  //装车单打印一下
  printEd: (params) =>
    axios.get(`${BASE.PRO2}/order/entruck/printEd`, { params }),

  // 自采
  //自采登记列表
  selfPurchaselist: params => axios.get(`${BASE.PRO2}/selfPurchase/list`, { params }),
  //自采品数据搜索
  selfPurchaselistsearch: params => axios.get(`${BASE.PRO2}/selfPurchase/list/search`, { params }),
  //自采品列表详情
  selfPurchasedetail: params => axios.get(`${BASE.PRO2}/selfPurchase/detail`, { params }),
  //自采品获取手机号
  selfPurchasemobile: params => axios.get(`${BASE.PRO2}/selfPurchase/mobile`, { params }),
  //自提点列表
  seldeliverylist: params => axios.get(`${BASE.PRO2}/selfPurchase/delivery/list`, { params }),
  //自采品打印标签
  productlabelprint: params => axios.post(`${BASE.PRO2}/selfPurchase/product/label/print`,  params ),
  //新增自采商品
  selfPurchaseadd: params => axios.post(`${BASE.PRO2}/selfPurchase/add`,  params ),
  //编辑自采商品
  selfPurchaseedit: params => axios.post(`${BASE.PRO2}/selfPurchase/edit`,  params ),
  //编辑自采商品复核详情
  selfPurchaseEntruckDetail: params => axios.post(`${BASE.PRO2}/selfPurchase/selfPurchaseEntruckDetail`,  params ),
  //编辑自采商品复核确认
  selfPurchaseEntruckSave: params => axios.post(`${BASE.PRO2}/selfPurchase/selfPurchaseEntruckSave`,  params ),

  //物流
  //装卸队管理 - 城市仓列表
  getLogisticList: (params) =>
    axios.post(`${BASE.PRO2}/logistic/getLogisticList`, params),
  //装卸队管理 - 新增装卸队
  addFleet: (params) => axios.post(`${BASE.PRO2}/logistic/addFleet`, params),
  //装卸队管理 - 装卸队详情
  getFleetInfo: (params) =>
    axios.post(`${BASE.PRO2}/logistic/getFleetInfo`, params),
  //装卸队管理 - 装卸队列表
  getFleetList: (params) =>
    axios.post(`${BASE.PRO2}/logistic/getFleetList`, params),
  //装卸队管理 - 装卸队编辑
  updateFleet: (params) =>
    axios.post(`${BASE.PRO2}/logistic/updateFleet`, params),
  //装卸队管理 - 禁用/启用装卸队
  updateFleetStatus: (params) =>
    axios.post(`${BASE.PRO2}/logistic/updateFleetStatus`, params),
  //装卸队账号管理 - 添加账号
  addFleetUser: (params) =>
    axios.post(`${BASE.PRO2}/logistic/addFleetUser`, params),
  //装卸队账号管理 - 账号详情
  getFleetUserInfo: (params) =>
    axios.post(`${BASE.PRO2}/logistic/getFleetUserInfo`, params),
  //装卸队账号管理 - 账号列表
  getFleetUserList: (params) =>
    axios.post(`${BASE.PRO2}/logistic/getFleetUserList`, params),
  //装卸队账号管理 - 账号编辑
  updateFleetUser: (params) =>
    axios.post(`${BASE.PRO2}/logistic/updateFleetUser`, params),
  //装卸队账号管理 - 禁用/启用账号
  updateFleetUserStatus: (params) =>
    axios.post(`${BASE.PRO2}/logistic/updateFleetUserStatus`, params),
  //发车情况 - 列表
  getStoreFleetLogisticList: (params) =>
    axios.post(`${BASE.PRO2}/logistic/getStoreFleetLogisticList`, params),
  //车辆提报 - 列表
  postDepartReportList: (params) =>
    axios.post(`${BASE.PRO2}/logistic/depart/report/list`, params),
  //车辆提报 - 筛选项
  postDepartReportSelectList: (params) =>
    axios.post(`${BASE.PRO2}/logistic/depart/report/select/list`, params),

  //发车情况 - 详情
  getStoreFleetLogisticInfo: (params) =>
    axios.post(`${BASE.PRO2}/logistic/getStoreFleetLogisticInfo`, params),
  //发车情况 - 编辑
  updateStoreFleetLogistic: (params) =>
    axios.post(`${BASE.PRO2}/logistic/updateStoreFleetLogistic`, params),

  // 自采
  //自采登记列表
  selfPurchaselist: (params) =>
    axios.get(`${BASE.PRO2}/selfPurchase/list`, { params }),
  //自采品数据搜索
  selfPurchaselistsearch: (params) =>
    axios.get(`${BASE.PRO2}/selfPurchase/list/search`, { params }),
  //自采品列表详情
  selfPurchasedetail: (params) =>
    axios.get(`${BASE.PRO2}/selfPurchase/detail`, { params }),
  //自采品获取手机号
  selfPurchasemobile: (params) =>
    axios.get(`${BASE.PRO2}/selfPurchase/mobile`, { params }),
  //自提点列表
  seldeliverylist: (params) =>
    axios.get(`${BASE.PRO2}/selfPurchase/delivery/list`, { params }),
  //自采品打印标签
  productlabelprint: (params) =>
    axios.post(`${BASE.PRO2}/selfPurchase/product/label/print`, params),
  //新增自采商品
  selfPurchaseadd: (params) =>
    axios.post(`${BASE.PRO2}/selfPurchase/add`, params),
  //编辑自采商品
  selfPurchaseedit: (params) =>
    axios.post(`${BASE.PRO2}/selfPurchase/edit`, params),
  //编辑自采商品复核详情
  selfPurchaseEntruckDetail: (params) =>
    axios.post(`${BASE.PRO2}/selfPurchase/selfPurchaseEntruckDetail`, params),
  //编辑自采商品复核确认
  selfPurchaseEntruckSave: (params) =>
    axios.post(`${BASE.PRO2}/selfPurchase/selfPurchaseEntruckSave`, params),
  // 城市仓
  selfPurchaseLogisticsList: (params) =>
    axios.get(`${BASE.PRO2}/selfPurchase/logistics/list`, params),
};

export default INDEX;
