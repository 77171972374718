/***
 * 接口安全加密方式
 * 1. post 参数加密 get不用
 * 2. post 参数 过滤 null undefined
 * 3. post 参数加入 timestamp 秒级时间戳
 * 4. post 参数 以键排序 升序
 * 5. post JSON.stringify
 * 6. md5 当前json
 * 7. 步骤6返回数据 拼上 liulubo
 * 8. md5 步骤7返回值
 */
import MD5 from "js-md5";
// import cloneDeep from "lodash/cloneDeep";
export function dataToEncryption(data = {}) {
  const params = {};
  // 过滤 null undefined
  const invalid = [null, undefined];
  Object.keys(data).forEach((_KEY) => {
    if (!invalid.includes(data[_KEY])) {
      params[_KEY] = data[_KEY];
    }
  });
  // 时间戳
  const time = new Date().getTime() / 1000;
  params.timestamp = parseInt(time);
  const keysList = Object.keys(params);
  // 键名 字母排序
  keysList.sort();
  // keysList.sort((a, b) => {
  //   return a - b;
  // });
  const sortParams = {};
  /**
   * encodeURIComponent url转义
   * decodeURIComponent url解码
   */
  keysList.map((_KEY) => {
    sortParams[_KEY] = params[_KEY];
  });
  // JSON.stringify
  const stringParams = JSON.stringify(sortParams, (key, value) => {
    // console.log('🆒 JSON.stringify', key, value);
    return value;
  });
  // md5
  const md5Params = MD5(stringParams);
  // 加入后缀
  const fullParams = md5Params + "liulubo";
  // 再次md5
  const lastParams = {
    ...sortParams,
    sign: MD5(fullParams),
  };
  // console.log('🆒 dataToEncryption 🆒', '\n ', '原始：', data, '\n ', '排序：', sortParams, '\n ', '序列化：', stringParams, '\n ', '最终：', lastParams);
  return lastParams;
}
