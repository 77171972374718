import { BASE } from './index';
import axios from './http';
const copyDelivery = {
  //小程序管理列表
  userlist: params => axios.get(`${BASE.PRO3}/applet/user/list`, { params }),
  //小程序管理添加
  usersave: params => axios.post(`${BASE.PRO3}/applet/user/save`, params),
  // 获取登录人菜单权限
  getRoleMenu: params => axios.get(`${BASE.PRO3}/get/role/menu`, { params }),
  // 管理员
  getDeliveryUser: params => axios.get(`${BASE.PRO3}/get/logistics/user`, { params }),
  saveDeliveryUser: params => axios.post(`${BASE.PRO3}/save/logistics/user`, params),
  updateDeliveryUserStatus: params => axios.post(`${BASE.PRO3}/logistics/user/status`, params),
  // 角色
  getRoleList: params => axios.get(`${BASE.PRO3}/get/role/list`, { params }),
  saveRole: params => axios.post(`${BASE.PRO3}/role/save`, params),
  saveRoleMenuIds: params => axios.post(`${BASE.PRO3}/role/menuIds/save`, params),
  getAllMenu: params => axios.get(`${BASE.PRO3}/get/menu/list`, { params }),


  // 接货单列表
  detailbatch: params => axios.post(`${BASE.PRO3}/add/order/detail/batch`, params),
  orderlist: params => axios.post(`${BASE.PRO3}/receive/order/list`, params),
  batchlist: params => axios.post(`${BASE.PRO3}/take/order/batch/list`, params),
  batchmemberlist: params => axios.post(`${BASE.PRO3}/take/order/batch/member/list`, params),
  batchmemberOrder: params => axios.post(`${BASE.PRO3}/take/order/batch/member/order`, params),
  deliveryOrderlist: params => axios.post(`${BASE.PRO3}/delivery/order/list`, params),
  deliveryOrderInfo: params => axios.post(`${BASE.PRO3}/delivery/order/info`, params),
  // 接货单详情
  orderdetail: params => axios.post(`${BASE.PRO3}/receive/order/detail`, params),
  // 接货单详情打印
  orderdetailprint: params => axios.post(`${BASE.PRO3}/receive/order/detail/print`, params),
  // 提货单打印
  orderlistti: params => axios.post(`${BASE.PRO3}/order/list`, params),
  // 分货单打印
  goodslistti: params => axios.post(`${BASE.PRO3}/divide/delivery/take/order/list`, params),
  // 提货单打印打印列表批量
  orderlisttiprint: params => axios.post(`${BASE.PRO3}/order/print`, params),
  // 提货单打印详情
  orderdetailti: params => axios.post(`${BASE.PRO3}/order/detail`, params),
  // 提货单核销
  tihuodanhexiao: params => axios.post(`${BASE.PRO3}/tihuodanhexiao`, params),
  // 点击核销
  ordercomplete: params => axios.post(`${BASE.PRO3}/order/complete`, params),
  updatePayPassword: params => axios.post(`${BASE.PRO3}/modify/password`, params),
  aftersaleList: params => axios.post(`${BASE.PRO3}/aftersale/un/list`, params),
  aftersaleEdList: params => axios.post(`${BASE.PRO3}/aftersale/ed/list`, params),
  aftersaleDetail: params => axios.post(`${BASE.PRO3}/aftersale/detail`, params),
};

export default copyDelivery;
