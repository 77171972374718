import { BASE } from './index';
import axios from './http';
const member = {
    // 用户标签列表
    memberlist: params => axios.post(`${BASE.PRO1}/member/label/list`, params),
    //编辑/新增标签
    memberlabelstore: params => axios.post(`${BASE.PRO1}/member/label/store`, params),
    //清除标签
    memberlabelclear: params => axios.post(`${BASE.PRO1}/member/label/clear`, params),
    //导入关联用户
    memberlabelbindimport: params => axios.post(`${BASE.PRO1}/member/label/bind/import`, params),
    //下拉列表
    memberlabelall: params => axios.post(`${BASE.PRO1}/member/label/all`, params),

};

export default member;
