import axios from 'axios';
import { Message } from 'element-ui';
// import router from "../router/router";
import { dataToEncryption } from "@/utils/tools/encryption.js";

/**
 * axios配置
 */
let config = {
  // headers: {
  //   'Cache-Control': 'no-cache',
  //   Pragma: 'no-cache'
  // },
  timeout: 10 * 1000 * 60
};
// 连接超时时间
axios.defaults.timeout = 10 * 60 * 1000;
// 创建 axios 实例
const ajax = axios.create(config);
// 设置 Post 请求头
ajax.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//请求拦截
ajax.interceptors.request.use((config) => {
  // console.log('🆒 dataToEncryption 🆒', config.url, config.data);
  const body = dataToEncryption(config.data);
  config.data = body;
  // 模拟
  // dataToEncryption({
  //   full: undefined,
  //   all: "",
  //   full2: 'undefined',
  //   year3: 'null',
  //   year2: '25555',
  //   food: '我是$测()试all22222实/时',
  //   year: null,
  //   beat: '111',
  // })
  // console.log(req, '请求',sessionStorage.getItem("token"))

  const headers = config.headers;
  const token = sessionStorage.getItem("token") ? sessionStorage.getItem("token") : '';
  if (!headers.Authorization) headers.Authorization = token;
  if (!headers.token) headers.token = token;
  return config;
})

/** 响应的成功状态 */
const successStatus = [200, 20000];

/**
 * 登录异常枚举
 * 需清除登录信息
*/
const LOGIN_FAIL_ENUM = {
  LOGIN_LOSE: {
    value: 30101,
    label: "未登录",
  },
  LOGIN_0VER_TIME: {
    value: 30102,
    label: "登录超时",
  },
  LOGIN_ABNORMITY: {
    value: 30103,
    label: "账号异常，请重新登录",
  },
  LOGIN_MOBILE: {
    value: 30104,
    label: "账号信息缺失",
  }
}
// 失败枚举列
const loginFailList = Object.values(LOGIN_FAIL_ENUM).map(item => item.value);
// console.log('🆒 loginFailList', loginFailList);

ajax.interceptors.response.use((response) => {
    // const { config } = response;
    // if (config.url.indexOf('login') === -1) {
    //     console.log('不是登录接口');
    //     // console.log('不是登录接口');
    // } else {
    //     console.log('是登录接口1');
    //     // console.log(ajax.defaults.headers);
    //     store.state.token = response.data.data.token;

    // }
    // console.log('555 🆒 request.use response', response);
    if (response) {
      const res = response.data;
      /** 文件类型直接返回 */
      if (Blob && res instanceof Blob) {
        let disposition = response.headers['content-disposition'];
        if (disposition) {
          /** 从返回信息里获取文件名 */
          let name = decodeURIComponent(disposition.split("''")?.[1]);
          res.filename = name;
        }
        // console.log("🆒 ajax.interceptors.response", response);
        return res;
      }

        //响应处理
        if (successStatus.includes(response.data.code)) {
          return response.data;
        }
        else if (loginFailList.includes(response.data.code)) {
            Message.error(response.data.msg ? response.data.msg : '接口错误')
            sessionStorage.setItem("token", '')
            location.reload()
            return Promise.reject();
        }
        else {
            // uni.$u.toast(result.data.message);
            Message.error(response.data.msg ? response.data.msg : '接口错误')
            return Promise.reject(response.data);
        }

    } else {
        // return response.data;
        Message.error(response.data.msg ? response.data.msg : '接口错误')
    }

}, err => {
    console.log(err, '接口错误')
    Message.error(err.response.data.msg ? err.response.data.msg : '接口错误')
    return Promise.reject(err);

});

export default ajax;
