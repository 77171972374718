import { BASE } from './index';
import axios from './http';
const customer = {
    // 客户分组列表
    servicesteamlist: params => axios.post(`${BASE.PRO1}/clientele/services/team/list`,  params ),
    // 客户分组详情
    servicesteamdetail: params => axios.post(`${BASE.PRO1}/clientele/services/team/detail`,  params ),
    // 客户添加分组
    servicesteamadd: params => axios.post(`${BASE.PRO1}/clientele/services/team/add`,  params ),
    // 客户编辑分组
    servicesteamedit: params => axios.post(`${BASE.PRO1}/clientele/services/team/edit`,  params ),
    // 客户用户列表
    servicesteambusinessUserd: params => axios.post(`${BASE.PRO1}/clientele/services/team/businessUser`,  params ),
    // 客户用户列表
    servicesteambusinessUserdall: params => axios.post(`${BASE.PRO1}/clientele/services/team/businessUser/all`,  params ),
    // 客户城市仓列表
    servicesteamlogistics: params => axios.post(`${BASE.PRO1}/clientele/services/team/logistics`,  params ),


};

export default customer;
