import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import api from '@/api/index';
import time from '@/assets/js/public.js'
import empty from '@/assets/js/public.js'
import tool from '@/assets/js/tools.js'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/style/global/reset.css'
import '@/assets/style/custom/index.scss'
import "lib-flexible-computer";
/** 项目中未使用的包，优化阶段注释掉 */
// import * as charts from 'echarts'
// Vue.prototype.$charts = charts
Vue.prototype.$api = api; // 将 api 挂在到 vue 原型上
Vue.prototype.tool = tool; // 将 api 挂在到 vue 原型上
Vue.prototype.$time = time; // 将 api 挂在到 vue 原型上
Vue.prototype.$empty = empty; // 将 api 挂在到 vue 原型上
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
