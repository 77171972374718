import { Message } from "element-ui";
let time = null;
export default {
  //入口函数，用于处理最外层的数组或对象。
  transformKeys(data) {
    if (Array.isArray(data)) {
      return data.map((item) => this.camelToSnake(item));
    } else if (typeof data === "object" && data !== null) {
      return this.camelToSnake(data);
    }
    return data;
  },
  //处理对象和数组，将它们的键从驼峰命名法转换为下划线命名法
  camelToSnake(obj) {
    // 将单个字符串从驼峰命名法转换为下划线命名法;
    const camelToSnakeCase = (str) => {
      return str.replace(
        /([a-z])([A-Z])/g,
        (match, p1, p2) => `${p1}_${p2.toLowerCase()}`
      );
    };

    if (Array.isArray(obj)) {
      return obj.map((item) => {
        if (typeof item === "object" && item !== null) {
          return transformKeys(item);
        }
        return item;
      });
    } else if (typeof obj === "object" && obj !== null) {
      const newObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const newKey = camelToSnakeCase(key);
          newObj[newKey] =
            typeof obj[key] === "object" && obj[key] !== null
              ? transformKeys(obj[key])
              : obj[key];
        }
      }
      return newObj;
    }
    return obj;
  },
  getDateDiff(dateStr) {
    // var publishTime = getDateTimeStamp(dateStr)/1000,
    var d_seconds,
      d_minutes,
      d_hours,
      d_days,
      timeNow = parseInt(new Date().getTime() / 1000),
      d,
      date = new Date(dateStr * 1000),
      Y = date.getFullYear(),
      M = date.getMonth() + 1,
      D = date.getDate(),
      H = date.getHours(),
      m = date.getMinutes(),
      s = date.getSeconds();
    //小于10的在前面补0
    if (M < 10) {
      M = "0" + M;
    }
    if (D < 10) {
      D = "0" + D;
    }
    if (H < 10) {
      H = "0" + H;
    }
    if (m < 10) {
      m = "0" + m;
    }
    if (s < 10) {
      s = "0" + s;
    }
    d = timeNow - dateStr;
    d_days = parseInt(d / 86400);
    d_hours = parseInt(d / 3600);
    d_minutes = parseInt(d / 60);
    d_seconds = parseInt(d);
    if (d_days > 0 && d_days < 3) {
      return d_days + "天前";
    } else if (d_days <= 0 && d_hours > 0) {
      return d_hours + "小时前";
    } else if (d_hours <= 0 && d_minutes > 0) {
      return d_minutes + "分钟前";
    } else if (d_seconds < 60) {
      if (d_seconds <= 0) {
        return "刚刚发表";
      } else {
        return d_seconds + "秒前";
      }
    } else if (d_days >= 3 && d_days < 30) {
      return M + "-" + D + " " + H + ":" + m;
    } else if (d_days >= 30) {
      return Y + "-" + M + "-" + D + " " + H + ":" + m;
    }
  },
  reset(form, nokey) {
    for (let key in form) {
      if (key != nokey) {
        if (Array.isArray(form[key])) {
          form[key] = [];
        } else if (typeof form[key] == "object") {
          for (let keys in form[key]) {
            form[key][keys] = "";
          }
        } else {
          form[key] = "";
        }
      }
    }
    return form;
  },
  message(msg, type = "info") {
    Message({
      message: msg,
      type: type,
    });
  },
  decodeURI(form) {
    for (let key in form) {
      if (typeof form[key] != "object") {
        form[key] = decodeURIComponent(form[key]);
      }
    }
    return form;
  },
  time(date) {
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;
    return y + "-" + m + "-" + d;
  },
  getMonth(date) {
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    return y + "-" + m;
  },
  getMonths(date) {
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    return m;
  },
  yesterday() {
    var time = new Date().getTime() - 24 * 60 * 60 * 1000;
    var yesday = new Date(time); // 获取的是前⼀天⽇期
    yesday =
      yesday.getFullYear() +
      "-" +
      (yesday.getMonth() > 9
        ? yesday.getMonth() + 1
        : "0" + (yesday.getMonth() + 1)) +
      "-" +
      (yesday.getDate() > 9 ? yesday.getDate() : "0" + yesday.getDate());
    return yesday;
  },
  format_num(num) {
    // debugger
    if (num) {
      num = String(num);
      if (num.indexOf(".") == -1) {
        num = num + ".00";
        return num;
      } else {
        let nums = num;
        nums = nums.split(".");
        if (nums[1].length > 2) {
          nums = nums[0] + "." + nums[1];
          nums = nums.substring(0, nums.indexOf(".") + 3);
          return nums;
        } else if (nums[1].length == 1) {
          return num + "0";
        } else {
          return num;
        }
      }
    } else {
      return "0.00";
    }
  },
  getResult(obj, url) {
    let url1 = this.updateUrl(obj);
    const newUrl = `${this.urlLink(url)}${url1}`;
    window.history.replaceState(null, null, newUrl);
  },
  urlLink(url) {
    console.log(url);
    let newUrl = "";
    if (url.indexOf("?") > -1) {
      newUrl = url.split("?")[0];
      console.log(newUrl);
      return newUrl;
    } else {
      console.log(url);
      return url;
    }
  },
  updateUrl(data) {
    console.log(data);
    let url = "";
    for (let key in data) {
      console.log(typeof data[key]);
      if (typeof data[key] == "object") {
        url += "&" + key + "=" + JSON.stringify(data[key]);
      } else {
        url += "&" + key + "=" + data[key];
      }
    }
    url = url.replace("&", "?");
    console.log(url);
    return url;
  },
  getUrl(url) {
    let httpdemo = "",
      obj = {},
      index = url.indexOf("?") + 1;
    httpdemo = url.substring(index).split("&");
    httpdemo.forEach((item) => {
      if (
        item.split("=")[1].indexOf(",") > -1 ||
        (item.split("=")[1].indexOf("%") > -1 &&
          decodeURIComponent(item.split("=")[1]).indexOf("[") > -1)
      ) {
        obj[item.split("=")[0]] = JSON.parse(
          decodeURIComponent(item.split("=")[1])
        );
      } else {
        if (item.split("=")[1].indexOf("%") > -1) {
          obj[item.split("=")[0]] = decodeURIComponent(item.split("=")[1]);
        } else {
          obj[item.split("=")[0]] =
            item.split("=")[1] == "null" ? "" : item.split("=")[1];
        }
      }
    });
    console.log(obj);
    return obj;
  },
  //时间戳转年月日时分秒
  timestampToTime(timestamp) {
    if (timestamp) {
      if (timestamp.toString().length == 10) {
        var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      } else {
        var date = new Date(timestamp);
      }

      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    } else {
      return "";
    }
  },
  //时间戳转年月日时分
  timestampToTimemin(timestamp) {
    if (timestamp) {
      if (timestamp.toString().length == 10) {
        var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      } else {
        var date = new Date(timestamp);
      }

      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      return Y + M + D + h + m;
    } else {
      return "";
    }
  },
  //时间戳转时分
  timestampToMiuse(timestamp) {
    if (timestamp) {
      if (timestamp.length == 10) {
        var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      } else {
        var date = new Date(timestamp);
      }
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      return h + m;
    } else {
      return "";
    }
  },
  //深度拷贝
  DeepCopy(target, source) {
    for (var k in source) {
      // 获取属性值
      var item = source[k];
      // 判断，数据类型为复杂数据类型Array和Object时，进行递归复制，为简单数据类型时才能进行直接赋值拷贝。
      // 由于Array也属于Object，因此判断时应先判断是否为Array类型再判断是否为Object类型。
      if (item instanceof Array) {
        target[k] = [];
        this.DeepCopy(target[k], item);
      } else if (item instanceof Object) {
        // debugger
        target[k] = {};
        this.DeepCopy(target[k], item);
      } else {
        target[k] = item;
      }
    }
    return target;
  },
  //银行卡尾号
  cardNumber(num) {
    let newNum = "";
    if (num.length > 4) {
      newNum = num.replace(/\s/g, "").replace(/(\d{4})\d+(\d{4})$/, "$2");
      return newNum;
    } else {
      return num;
    }
  },
  toDecimal2(x) {
    var f = parseFloat(x);
    if (isNaN(f)) {
      return false;
    }
    var f = Math.round(x * 100) / 100;
    var s = f.toString();
    var rs = s.indexOf(".");
    if (rs < 0) {
      rs = s.length;
      s += ".";
    }
    while (s.length <= rs + 2) {
      s += "0";
    }
    return s;
  },
  //分转元
  format_money(fen) {
    if (fen != 0 && fen) {
      var num = fen;
      num = fen * 0.01;
      num += "";
      var reg =
        num.indexOf(".") > -1
          ? /(\d{1,3})(?=(?:\d{3})+\.)/g
          : /(\d{1,3})(?=(?:\d{3})+$)/g;
      num = num.replace(reg, "$1");
      num = this.toDecimal2(num);
      return num;
    } else {
      return "0.00";
    }
  },
  format(money) {
    money = money + "";
    let res = money.replace(/\d+/, function (num) {
      // 先提取整数部分
      return num.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
        return $1 + ",";
      });
    });
    return res;
  },
  add(arg1, arg2) {
    (arg1 = arg1.toString()), (arg2 = arg2.toString()); // 将传入的数据转化为字符串
    var arg1Arr = arg1.split("."), // 将小数的数据从小数点的位置拆开
      arg2Arr = arg2.split("."),
      d1 = arg1Arr.length == 2 ? arg1Arr[1] : "", // 获取第一个数的小数点的长度
      d2 = arg2Arr.length == 2 ? arg2Arr[1] : ""; // 获取第二个数的小数点的长度
    var maxLen = Math.max(d1.length, d2.length); // 获取小数点长度较大的值
    var m = Math.pow(10, maxLen); // 这里表示10的小数点长度次方 也就是说如果小数点长度为2 m的值就是100 如果小数点长度是3 m的值就是1000如果不懂请自行查找api
    var result = Number(((arg1 * m + arg2 * m) / m).toFixed(maxLen)); // 将小数转化为整数后相加在除掉两个数乘过的倍数然后去小数点较长的长度的小数位数
    var d = arguments[2]; // 第三个参数用户可以自行决定是否要传递 用来定义要保留的小数长度
    return typeof d === "number" ? Number(result.toFixed(d)) : result;
  },
  sun(arg1, arg2) {
    return this.add(arg1, -arg2);
  },
  changeValue(value) {
    value = value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
    value = value.replace(/^\./g, ""); //验证第一个字符是数字而不是.
    value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的.
    value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    return value;
  },
  //防止多次提交
  debounce(fuc, delay = 500) {
    if (time) {
      clearTimeout(time);
    }
    time = setTimeout(fuc, delay);
  },
};
