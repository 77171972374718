import { BASE } from './index';
import axios from './http';
const share = {
    // 用户分享列表
    getsharelist: params => axios.post(`${BASE.PRO1}/member/share/list`,  params ),
    // 推品统计列表
    getsharemembergrouplist: params => axios.post(`${BASE.PRO1}/share/member/group/data`,  params ),
    // 商品推广详情列表
    getsharemembergroupdetaillist: params => axios.post(`${BASE.PRO1}/share/member/group/detail/list`,  params ),
};

export default share;
