import { BASE } from "./index";
import axios from "./http";

const general = {
  getRoleMenu: (params) =>
    axios.get(`${BASE.PRO1}/general/role/menu`, { params }),
  logisticsMember: (params) =>
    axios.get(`${BASE.PRO1}/member/logistics`, { params }),
  logisticsSuper: (params) =>
    axios.get(`${BASE.PRO1}/member/super/logistics`, { params }),
  deliverySuper: (params) =>
    axios.get(`${BASE.PRO1}/member/super/delivery/${params.logistics_id}`),
  memberList: (params) => axios.post(`${BASE.PRO1}/member/list`, params),
  addSuper: (params) => axios.post(`${BASE.PRO1}/add/member/super`, params),
  salerefundlist: (params) =>
    axios.post(`${BASE.PRO1}/aftersale/review/refund/list`, params),
  salenewunlist: (params) =>
    axios.post(`${BASE.PRO1}/aftersale/new/un/list`, params),
  salenewlist: (params) =>
    axios.post(`${BASE.PRO1}/aftersale/new/list`, params),
  superList: (params) => axios.post(`${BASE.PRO1}/member/super/list`, params),
  getRefundExport: (params) =>
    axios.post(`${BASE.PRO1}/aftersale/review/refund/export`, params),
  logisticsWithdrawList: (params) =>
    axios.get(`${BASE.PRO1}/logistics/withdraw/list`, { params }),
  banksList: (params) => axios.get(`${BASE.PRO1}/banksList`, { params }),
  branchList: (params) => axios.get(`${BASE.PRO1}/banksBranch`, { params }),
  productList: (params) => axios.post(`${BASE.PRO1}/product/list`, params),
  saleLogisticsList: (params) =>
    axios.get(`${BASE.PRO1}/product/saleLogisticsList`, { params }),
  purchaseList: (params) =>
    axios.post(`${BASE.PRO1}/purchase/settle/list`, params),
  getAllMenu: (params) => axios.get(`${BASE.PRO1}/general/menu`, { params }),
  generalUserList: (params) =>
    axios.get(`${BASE.PRO1}/general/user/list`, { params }),
  generalUserDelete: (params) =>
    axios.post(`${BASE.PRO1}/general/user/delete`, params),
  generalUserResetPassword: (params) =>
    axios.post(`${BASE.PRO1}/general/user/reset/password`, params),
  updateGeneralUserStatus: (params) =>
    axios.post(`${BASE.PRO1}/general/user/status`, params),
  generalRoleList: (params) =>
    axios.get(`${BASE.PRO1}/role/general/list`, { params }),
  addGeneralUser: (params) =>
    axios.post(`${BASE.PRO1}/add/general/user`, params),
  updateGeneralUser: (params) =>
    axios.post(`${BASE.PRO1}/update/general/user`, params),
  roleGeneralList: (params) =>
    axios.get(`${BASE.PRO1}/role/general/list`, { params }),
  addRoleGeneral: (params) =>
    axios.post(`${BASE.PRO1}/add/role/general`, params),
  updateRoleGeneral: (params) =>
    axios.post(`${BASE.PRO1}/update/role/general`, params),
  updateRoleMenuIds: (params) =>
    axios.post(`${BASE.PRO1}/update/role/memuids`, params),
  freightSettleDetail: (params) =>
    axios.post(`${BASE.PRO1}/freight/settle/detail`, params),
  /** 总控-角色-数据权限-集配列表 */
  ajaxGetLogisticsForRole: (params) =>
    axios.post(`${BASE.PRO1}/general/getLogisticBusinessList`, params),
  /** 总控-角色-数据权限-更新 */
  ajaxDataRoleUpdate: (params) =>
    axios.post(`${BASE.PRO1}/general/role/setLogisticBusiness`, params),
  purchaseDetail: (params) =>
    axios.post(`${BASE.PRO1}/purchase/settle/detail`, params),

  logisticsList: (params) => axios.post(`${BASE.PRO1}/logistics/list`, params),
  calmList: (params) =>
    axios.post(`${BASE.PRO1}/order/detail/calm/detail/list`, params),
  flowList: (params) =>
    axios.post(`${BASE.PRO1}/order/detail/flow/list`, params),
  orderExport: (params) =>
    axios.get(`${BASE.PRO1}/order/list/export`, { params }),
  bannerList: (params) => axios.post(`${BASE.PRO1}/banner/list`, params),
  bannerAdd: (params) => axios.post(`${BASE.PRO1}/banner/add`, params),
  bannerDelete: (params) => axios.post(`${BASE.PRO1}/banner/delete`, params),
  bannerEdit: (params) => axios.post(`${BASE.PRO1}/banner/edit`, params),
  supplierList: (params) => axios.post(`${BASE.PRO1}/supplier/list`, params),
  supplierOrderList: (params) =>
    axios.post(`${BASE.PRO1}/supplier/order/list`, params),
  withdrawList: (params) =>
    axios.post(`${BASE.PRO1}/supplier/withdraw/list`, params),
  batchList: (params) =>
    axios.post(`${BASE.PRO1}/supplier/order/batch/list`, params),
  buyerAll: (params) => axios.get(`${BASE.PRO1}/buyer/all`, { params }),
  buyerList: (params) => axios.post(`${BASE.PRO1}/buyer/list`, params),
  businessList: (params) =>
    axios.get(`${BASE.PRO1}/business/collective`, { params }),
  selltimeList: (params) => axios.post(`${BASE.PRO1}/selltime/list`, params),
  aftersaleList: (params) =>
    axios.post(`${BASE.PRO1}/aftersale/un/list`, params),
  afternewsaleList: (params) =>
    axios.post(`${BASE.PRO1}/aftersale/new/list`, params),
  buyerSupplierList: (params) =>
    axios.post(`${BASE.PRO1}/buyer/supplier/list`, params),
  orderList: (params) => axios.post(`${BASE.PRO1}/order/list`, params),
  settleList: (params) =>
    axios.post(`${BASE.PRO1}/supplier/order/settle/list`, params),
  aftersaleEdList: (params) =>
    axios.post(`${BASE.PRO1}/aftersale/ed/list`, params),
  orderDetail: (params) => axios.post(`${BASE.PRO1}/order/detail`, params),
  withdrawDetail: (params) =>
    axios.post(`${BASE.PRO1}/supplier/withdraw/detail`, params),
  logisticsStatus: (params) =>
    axios.post(`${BASE.PRO1}/logistics/status`, params),
  deliveryStatus: (params) =>
    axios.post(`${BASE.PRO1}/delivery/status`, params),
  buyerStatus: (params) => axios.post(`${BASE.PRO1}/buyer/status`, params),
  selltimeStatus: (params) =>
    axios.post(`${BASE.PRO1}/selltime/status`, params),
  deliveryList: (params) => axios.post(`${BASE.PRO1}/delivery/list`, params),
  addLogistics: (params) => axios.post(`${BASE.PRO1}/add/logistics`, params),
  addDelivery: (params) => axios.post(`${BASE.PRO1}/add/delivery`, params),
  updatePaymentImg: (params) =>
    axios.post(`${BASE.PRO1}/update/supplier/withdraw/payment/img`, params),
  updatePaymentBack: (params) =>
    axios.post(`${BASE.PRO1}/back/supplier/withdraw`, params),
  addbBuyer: (params) => axios.post(`${BASE.PRO1}/add/buyer`, params),
  addbSelltime: (params) => axios.post(`${BASE.PRO1}/add/selltime`, params),
  updateLogistics: (params) =>
    axios.post(`${BASE.PRO1}/update/logistics`, params),
  updateBuyer: (params) => axios.post(`${BASE.PRO1}/update/buyer`, params),
  updateDelivery: (params) =>
    axios.post(`${BASE.PRO1}/update/delivery`, params),
  updateSelltime: (params) =>
    axios.post(`${BASE.PRO1}/update/selltime`, params),
  updateSupplier: (params) =>
    axios.post(`${BASE.PRO1}/update/supplier/withdraw`, params),
  updateAftersale: (params) =>
    axios.post(`${BASE.PRO1}/update/aftersale`, params),
  changeBuyer: (params) =>
    axios.post(`${BASE.PRO1}/change/supplier/buyer`, params),
  aftersaleDetail: (params) =>
    axios.post(`${BASE.PRO1}/aftersale/detail`, params),
  aftersalenewDetail: (params) =>
    axios.post(`${BASE.PRO1}/aftersale/new/detail`, params),
  aftersaleStep: (params) =>
    axios.post(`${BASE.PRO1}/aftersale/new/step`, params),
  aftersalenewHandle: (params) =>
    axios.post(`${BASE.PRO1}/aftersale/new/handle`, params),
  supplierOrderDetail: (params) =>
    axios.post(`${BASE.PRO1}/supplier/order/detail`, params),
  settleDetail: (params) =>
    axios.post(`${BASE.PRO1}/supplier/order/settle/detail`, params),
  delCategories: (params) =>
    axios.post(`${BASE.PRO1}/delete/categories`, params),
  addOrder: (params) => axios.post(`${BASE.PRO1}/add/supplier/order`, params),
  addOrder1: (params) =>
    axios.post(`${BASE.PRO1}/add/supplier/order/calm`, params),
  uploadImage: (params) => axios.post(`${BASE.PRO1}/upload`, params),
  freightList: (params) =>
    axios.post(`${BASE.PRO1}/freight/settle/list`, params),
  withdrawAuditPayment: (params) =>
    axios.post(`${BASE.PRO1}/logistics/withdraw/auditPayment`, params),
  upgradeLogistics: (params) =>
    axios.post(`${BASE.PRO1}/upgrade/logistics`, params),
  uploadPaymentImg: (params) =>
    axios.post(`${BASE.PRO1}/logistics/withdraw/uploadPaymentImg`, params),
  resetPassword: (params) =>
    axios.post(`${BASE.PRO1}/reset/business/password`, params),
  logisticsWithdrawDetail: (params) =>
    axios.get(`${BASE.PRO1}/logistics/withdraw/detail`, { params }),
  //编辑在售商品详情接口
  productinfo: (params) => axios.post(`${BASE.PRO1}/product/info`, params),
  //商品分类接口
  categoriesList: (params) =>
    axios.get(`${BASE.PRO1}/categoriesList`, { params }),
  //标签接口
  taglist: (params) =>
    axios.post(`${BASE.PRO1}/product/promotion/tag/list`, params),
  //采购员列表
  productbuyerlist: (params) =>
    axios.post(`${BASE.PRO1}/product/buyer/list`, params),
  //编辑商品
  productedit: (params) => axios.post(`${BASE.PRO1}/product/edit`, params),
  //查询条件
  searchdata: (params) =>
    axios.post(`${BASE.PRO1}/product/list/search/data`, params),

    //绑定供应商列表：
    bindsupplierList: (params) =>
      axios.post(`${BASE.PRO1}/move/bind/supplier/list`, params),
    //换绑采购列表:
    movebuyerlist: (params) =>
      axios.post(`${BASE.PRO1}/move/buyer/list`, params),
    //绑定供应商迁移::
    movebindsupplier: (params) =>
      axios.post(`${BASE.PRO1}/move/bind/supplier`, params),
    // 发品供应商列表:::
    bindsupplierproductList: (params) =>
      axios.post(`${BASE.PRO1}/move/bind/supplier/product/list`, params),
    //发品供应商迁移:
    bindsupplierproduct: (params) =>
      axios.post(`${BASE.PRO1}/move/bind/supplier/product`, params),
  //重新发起
  contractrestart: (params) =>
    axios.post(`${BASE.PRO1}/supplier/contract/restart`, params),
  //重新发起
  bicontractrestartexport: (params) =>
    axios.post(`${BASE.EXPORT_CENTER}/bi/aftersale/comment/export`, params),
  //售后列表
  aftersalecomment: (params) =>
    axios.post(`${BASE.PRO1}/aftersale/comment`, params),
  //售后导出
  aftersalefinishexport: (params) =>
    axios.post(`${BASE.EXPORT_CENTER}/bi/aftersale/finish/export`, params),
   //供应商临时提现
   suppliercontractcancel: (params) =>
  axios.post(`${BASE.PRO1}/supplier/contract/cancel`, params),
   //供应商下线
   supplierexpiredate: (params) =>
  axios.post(`${BASE.PRO1}/supplier/expire/date`, params),
};


export default general;
