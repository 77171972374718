import { BASE } from "./index";
import axios from "./http";

const examine = {
    //审核商品列表
    productlist: (params) =>axios.post(`${BASE.PRO1}/categories/audit/product/list`,  params ),
    //审核商品列表分类参数
    producttotal: (params) =>axios.post(`${BASE.PRO1}/categories/audit/product/total`,  params ),
    //审核商品通过驳回
    producthandle: (params) =>axios.post(`${BASE.PRO1}/categories/audit/product/handle`,  params ),
};

export default examine;
