/**
 * 接口请求地址，暴露
 */
export const BASE = {
  PRO: process.env.VUE_APP_CENTER_URL,
  PRO1: process.env.VUE_APP_GENERAL_URL,
  PRO2: process.env.VUE_APP_LOGISTICS_URL,
  PRO3: process.env.VUE_APP_DELIVERY_URL,
  PRO4: process.env.VUE_APP_TDC_URL,
  EXPORT_CENTER: process.env.VUE_APP_EXPORT_CENTER_URL
};
// console.log('🆒 BASE 888', process.env, BASE);

// 动态导出api
const files = require.context('.', false, /\.js$/)
const api = {};
files.keys().forEach(key => {
  if (key === './index.js') return
  api[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})

export default api
