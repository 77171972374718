import { BASE } from './index';
import axios from './http';
const Sales = {
    // 动销列表-区间
    getday_range: params => axios.post(`${BASE.PRO1}/product/sell/stat/day_range`,  params ),
    // 动销列表-每日
    getsellstatday: params => axios.post(`${BASE.PRO1}/product/sell/stat/day`,  params ),
    // 导出动销列表-每日：
    sellDayexport: params => axios.post(`${BASE.EXPORT_CENTER}/bi/product/sellDay/export`,  params ),
    // 导出动销列表-区间：
    sellDayRangeexport: params => axios.post(`${BASE.EXPORT_CENTER}/bi/product/sellDayRange/export`,  params ),
};

export default Sales;
