import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    meta: {
      title: "登录",
    },
    component: () => import("../views/login.vue"),
  },

  {
    path: "/menu",
    name: "menu",
    redirect: "/meResume",
    component: () => import("../views/menu/index.vue"),
    children: [
      {
        path: "/meResume", //首页
        name: "meResume",
        meta: {
          title: "首页",
        },
        component: () => import("../views/meResume/index.vue"),
      },
      {
        path: "/coreadmin", //中心账号管理
        name: "coreadmin",

        meta: {
          title: "中心账号管理",
        },
        component: () => import("../views/meResume/coreadmin.vue"),
      },
      {
        path: "/changePassword", //修改密码
        name: "changePassword",

        meta: {
          title: "修改密码",
        },
        component: () => import("../views/meResume/changePassword.vue"),
      },
    ],
  },
  {
    path: "/menuzhong",
    name: "menuzhong",
    redirect: "/Urbanwarehouse",
    component: () => import("../views/menu/indexzong.vue"),
    children: [
      {
        path: "/admin", //用户管理
        name: "admin",
        meta: {
          title: "用户管理",
        },
        component: () => import("../views/system/admin.vue"),
      },
      {
        path: "/role", //角色管理
        name: "role",

        meta: {
          title: "角色管理",
        },
        component: () => import("../views/system/role.vue"),
      },
      {
        path: "/member", // 普通用户
        name: "CommonMember",

        meta: {
          title: "普通用户",
        },
        component: () => import("../views/system/common-member/index.vue"),
      },
      {
        path: "/usertags", // 用户标签
        name: "usertags",

        meta: {
          title: "用户标签",
        },
        component: () => import("../views/system/usertags.vue"),
      },
      {
        path: "/Followuplist", // 回访记录
        name: "Followuplist",

        meta: {
          title: "回访记录",
        },
        component: () => import("../views/system/Followup/Followuplist.vue"),
      },
      {
        path: "/Followuprecords", // 回访记录列表
        name: "Followuprecords",

        meta: {
          title: "回访记录列表",
        },
        component: () => import("../views/system/Followup/Followuprecords.vue"),
      },
      {
        path: "/Followuplistcity", // 回访记录
        name: "Followuplistcity",

        meta: {
          title: "回访记录",
        },
        component: () => import("../views/system/Followup/Followuplistcity.vue"),
      },
      {
        path: "/Followuprecordscity", // 回访记录列表
        name: "Followuprecordscity",

        meta: {
          title: "回访记录列表",
        },
        component: () => import("../views/system/Followup/Followuprecordscity.vue"),
      },
      {
        path: "/memberlist", //会员列表
        name: "member",

        meta: {
          title: "会员列表",
        },
        component: () => import("../views/Urbanwarehouse/member.vue"),
      },
      {
        path: "/updatePassword", //会员列表
        name: "updatePassword",

        meta: {
          title: "修改密码",
        },
        component: () => import("../views/delivery/updatePassword.vue"),
      },
      {
        path: "/customer", // 大客户
        name: "BigBoss",
        meta: {
          title: "大客户",
        },
        component: () => import("../views/system/big-boss/index.vue"),
      },
      {
        path: "/consumptionDetails", //消费明细
        name: "consumptionDetails",
        meta: {
          title: "消费明细",
        },
        component: () => import("../views/system/consumptionDetails/index.vue"),
      },
      {
        path: "/Urbanwarehouse", // 总控-城市仓管理
        name: "CityStoreManage",
        meta: {
          title: "城市仓管理",
        },
        component: () => import("../views/general/city-store-manage/index"),
      },
      {
        path: "/origin", // 总控-产地
        name: "origin",
        meta: {
          title: "产地",
        },
        component: () => import("../views/general/origin/index"),
      },
      {
        path: "/complaint-suggestion", // 总控-投诉与建议
        name: "complaint-suggestion",
        meta: {
          title: "投诉与建议",
        },
        component: () => import("../views/general/complaint-suggestion/index"),
      },
      {
        path: "/recommended-products", // 总控-推品记录
        name: "recommended-products",
        meta: {
          title: "推品记录",
        },
        component: () => import("../views/general/recommended-products/index"),
      },
      {
        path: "/self-support-store", // 总控-自营仓管理
        name: "SelfSupportStore",
        meta: {
          title: "自营仓管理",
        },
        component: () => import("../views/general/self-support-store/index"),
      },
      {
        path: "/supplier", // 供货商管理
        name: "supplier",
        meta: {
          title: "供货商管理",
        },
        component: () => import("../views/general/supplier.vue"),
      },
      {
        path: "/signature", // 线上签约
        name: "signature",
        meta: {
          title: "线上签约",
        },
        component: () => import("../views/general/signature.vue"),
      },
      {
        path: "/buyer", //地采采购
        name: "buyer",
        meta: {
          title: "地采采购",
        },
        component: () => import("../views/general/buyer.vue"),
      },
      {
        path: "/JiCaiBuyer", //基采采购
        name: "JiCaiBuyer",
        meta: {
          title: "基采采购",
        },
        component: () => import("../views/general/JiCaiBuyer.vue"),
      },
      {
        path: "/category", //品类管理
        name: "category",
        meta: {
          title: "品类管理",
        },
        component: () => import("../views/general/category.vue"),
      },
      {
        path: "/categoryChildren", //二级品类管理
        name: "categoryChildren",
        meta: {
          title: "二级品类管理",
        },
        component: () => import("../views/general/categoryChildren.vue"),
      },
      {
        path: "/Selected-products", //审核商品
        name: "Selected-products",
        meta: {
          title: "审核商品",
        },
        component: () => import("../views/general/Selected-products/index.vue"),
      },
      {
        path: "/productsStatistics", //推品统计
        name: "productsStatistics",
        meta: {
          title: "推品统计",
        },
        component: () =>
          import("../views/general/products/productsStatistics.vue"),
      },
      {
        path: "/productsDetails", //推品详情
        name: "productsDetails",
        meta: {
          title: "推品详情",
        },
        component: () =>
          import("../views/general/products/productsDetails.vue"),
      },
      {
        path: "/productsRecord", //推品记录
        name: "productsRecord",
        meta: {
          title: "推品记录",
        },
        component: () => import("../views/general/products/productsRecord.vue"),
      },
      {
        path: "/sale-goods", //在售商品
        name: "sale-goods",
        meta: {
          title: "在售商品",
        },
        component: () => import("../views/general/Salegoods.vue"),
      },
      {
        path: "/down-goods", //下架商品
        name: "down-goods",
        meta: {
          title: "下架及审核商品",
        },
        component: () => import("../views/general/Downgoods.vue"),
      },
      {
        path: "/keyword-search", //关键词搜索
        name: "keyword-search",
        meta: {
          title: "关键词搜索",
        },
        component: () => import("../views/general/keyword-search/index.vue"),
      },
      {
        path: "/hotword-search", //热词搜索
        name: "hotword-search",
        meta: {
          title: "热词搜索",
        },
        component: () => import("../views/general/hotword-search/index.vue"),
      },
      {
        path: "/Goodsdetail", //商品详情
        name: "Goodsdetail",
        meta: {
          title: "查看详情",
        },
        component: () => import("../views/general/Goodsdetail.vue"),
      },
      {
        path: "/Saletime", //售卖时间模板
        name: "Saletime",
        meta: {
          title: "售卖时间模板",
        },
        component: () => import("../views/general/Saletime.vue"),
      },
      {
        path: "/orderlist", //订单列表
        name: "orderlist",
        meta: {
          title: "订单列表",
        },
        component: () => import("../views/general/orderlist.vue"),
      },
      // {
      //   path: "/Supplylist", //供货单
      //   name: "Supplylist",
      //   meta: {
      //     title: "供货单",
      //   },
      //   component: () => import("../views/general/Supplylist.vue"),
      // },
      {
        path: "/isSupply", //供货单
        name: "isSupply",
        meta: {
          title: "供货单(已配货)",
        },
        component: () => import("../views/general/isSupply.vue"),
      },
      {
        path: "/noSupply", //供货单
        name: "noSupply",
        meta: {
          title: "供货单(未配货)",
        },
        component: () => import("../views/general/noSupply.vue"),
      },
      {
        path: "/goodsFollow", //商品跟踪
        name: "goodsFollow",
        meta: {
          title: "商品跟踪",
        },
        component: () => import("../views/general/goodsFollow.vue"),
      },
      {
        path: "/Supplylist", //供货单
        name: "Supplylist",
        meta: {
          title: "供货单",
        },
        component: () => import("../views/general/Supplylist.vue"),
      },
      {
        path: "/Supplylist1", //供货单
        name: "Supplylist1",
        meta: {
          title: "供货单列表",
        },
        component: () => import("../views/general/supplyList1.vue"),
      },
      {
        path: "/supplierDetail", // 供货单详情
        name: "supplierDetail",
        meta: {
          title: "查看详情",
        },
        component: () => import("../views/general/supplierDetail.vue"),
      },
      {
        path: "/orderDetail", // 订单详情
        name: "orderDetail",
        meta: {
          title: "订单详情",
        },
        component: () => import("../views/general/orderDetail.vue"),
      },
      {
        path: "/customerService", // 客服分组
        name: "customerService",
        meta: {
          title: "客服分组",
        },
        component: () =>
          import("../views/general/customerService/customerService.vue"),
      },
      {
        path: "/Aftersaleslist", //待审核售后单
        name: "Aftersaleslist",
        meta: {
          title: "待审核售后单",
        },
        component: () => import("../views/general/Aftersaleslist.vue"),
      },
      {
        path: "/skuCodeSearch", //货号搜索
        name: "skuCodeSearch",
        meta: {
          title: "货号搜索",
        },
        component: () =>
          import("../views/general/skuCodeSearch/skuCodeSearch.vue"),
      },
      {
        path: "/newAfterList", //待审核售后单
        name: "newAfterList",
        meta: {
          title: "待审核售后单",
        },
        component: () => import("../views/general/newAfterList.vue"),
      },
      {
        path: "/section", //区间销量查询
        name: "section",
        meta: {
          title: "区间销量查询",
        },
        component: () => import("../views/general/SalesStatistics/section.vue"),
      },
      {
        path: "/user-demand-submission", //用户需求提报
        meta: {
          title: "用户需求提报",
        },
        component: () =>
          import("../views/general/user-demand-submission/index.vue"),
      },
      {
        path: "/Supplydate", //供货日销量查询
        name: "Supplydate",
        meta: {
          title: "供货日销量查询",
        },
        component: () =>
          import("../views/general/SalesStatistics/Supplydate.vue"),
      },
      {
        path: "/platInsert", // 总控-售后单管理-平台介入
        name: "PlatformParticipation",
        meta: {
          title: "平台介入",
        },
        component: () =>
          import("../views/general/platform-participation/index"),
      },
      {
        path: "/platInsertnone", // 总控-售后单管理-非平台介入
        name: "UnPlatformParticipation",
        meta: {
          title: "非平台介入",
        },
        component: () =>
          import("../views/general/un-platform-participation/index"),
      },
      {
        path: "/newAfterListed", //售后单
        name: "newAfterListed",
        meta: {
          title: "售后单",
        },
        component: () => import("../views/general/newAfterListed.vue"),
      },
      {
        path: "/evaluation", //售后评价
        name: "evaluation",
        meta: {
          title: "售后评价",
        },
        component: () => import("../views/general/evaluation.vue"),
      },

      {
        path: "/Aftersaleslistcomplete", //已完成售后单查询
        name: "Aftersaleslistcomplete",
        meta: {
          title: "已完成售后单查询",
        },
        component: () => import("../views/general/Aftersaleslistcomplete.vue"),
      },
      {
        path: "/afterDetail", //审核售后单
        name: "afterDetail",
        meta: {
          title: "售后单审核",
        },
        component: () => import("../views/general/afterDetail.vue"),
      },
      {
        path: "/newAfterDetail", //审核售后单
        name: "newAfterDetail",
        meta: {
          title: "售后单详情",
        },
        component: () => import("../views/general/newAfterDetail.vue"),
      },
      {
        path: "/newAfterCheck", //审核售后单
        name: "newAfterCheck",
        meta: {
          title: "售后单审核",
        },
        component: () => import("../views/general/newAfterCheck.vue"),
      },
      {
        path: "/ztAftersaleslist", //待审核售后单
        name: "ztAftersaleslist",
        meta: {
          title: "待审核售后单",
        },
        component: () => import("../views/delivery/Aftersaleslist.vue"),
      },
      {
        path: "/salesman", //销售人员
        name: "salesman",
        meta: {
          title: "销售人员",
        },
        component: () => import("../views/delivery/SalesPromotion/salesman.vue"),
      },

      {
        path: "/ztAftersaleslistcomplete", //已完成售后单查询
        name: "ztAftersaleslistcomplete",
        meta: {
          title: "已完成售后单查询",
        },
        component: () => import("../views/delivery/Aftersaleslistcomplete.vue"),
      },
      {
        path: "/ztafterDetail", //审核售后单
        name: "ztafterDetail",
        meta: {
          title: "售后单审核",
        },
        component: () => import("../views/delivery/afterDetail.vue"),
      },
      {
        path: "/tdcAftersaleslist", //待审核售后单
        name: "tdcAftersaleslist",
        meta: {
          title: "待审核售后单",
        },
        component: () => import("../views/Urbanwarehouse/Aftersaleslist.vue"),
      },

      {
        path: "/tdcAftersaleslistcomplete", //已完成售后单查询
        name: "tdcAftersaleslistcomplete",
        meta: {
          title: "已完成售后单查询",
        },
        component: () =>
          import("../views/Urbanwarehouse/Aftersaleslistcomplete.vue"),
      },
      {
        path: "/tdcafterDetail", //审核售后单
        name: "tdcafterDetail",
        meta: {
          title: "售后单审核",
        },
        component: () => import("../views/Urbanwarehouse/afterDetail"),
      },
      {
        path: "/settlement", //结算单管理
        name: "settlement",
        meta: {
          title: "结算单管理",
        },
        component: () => import("../views/general/settlement.vue"),
      },
      {
        path: "/settleDetail", //审核售后单
        name: "settleDetail",
        meta: {
          title: "结算单详情",
        },
        component: () => import("../views/general/settleDetail.vue"),
      },
      {
        path: "/Payment", //打款管理
        name: "Payment",
        meta: {
          title: "打款管理",
          keepAlive: true,
        },
        component: () => import("../views/general/Payment.vue"),
      },
      {
        path: "/payDetail", //打款管理
        name: "payDetail",
        meta: {
          title: "打款详情",
        },
        component: () => import("../views/general/payDetail.vue"),
      },
      {
        path: "/freightSettlement", //运费结算管理
        name: "freightSettlement",
        meta: {
          title: "运费结算管理",
        },
        component: () => import("../views/general/freightSettlement.vue"),
      },
      {
        path: "/freightDetail", //运费结算管理
        name: "freightDetail",
        meta: {
          title: "订单详情",
        },
        component: () => import("../views/general/freightDetail.vue"),
      },
      {
        path: "/substituteMining", //服务费结算管理
        name: "substituteMining",
        meta: {
          title: "服务费结算管理",
        },
        component: () => import("../views/general/substituteMining.vue"),
      },
      {
        path: "/cityDynamicData", //城市仓动账数据
        name: " cityDynamicData",
        meta: {
          title: "城市仓动账数据",
        },
        component: () => import("../views/general/city-dynamic-data/index.vue"),
      },
      {
        path: "/supplierDynamicData", //供应商动账数据
        name: " supplierDynamicData",
        meta: {
          title: "供应商动账数据",
        },
        component: () =>
          import("../views/general/supplier-dynamic-data/index.vue"),
      },
      {
        path: "/substituteDetail", //代采结算管理
        name: "substituteDetail",
        meta: {
          title: "服务费详情",
        },
        component: () => import("../views/general/substituteDetail.vue"),
      },
      {
        path: "/cityPayment", //城市仓打款管理
        name: "cityPayment",
        meta: {
          title: "城市仓打款管理",
        },
        component: () => import("../views/general/cityPayment.vue"),
      },
      {
        path: "/cityPaymentDetail", //城市仓打款详情
        name: "cityPaymentDetail",
        meta: {
          title: "打款详情",
        },
        component: () => import("../views/general/cityPaymentDetail.vue"),
      },
      {
        path: "/banner", //banner
        name: "banner",
        meta: {
          title: "banner列表",
        },
        component: () => import("../views/general/banner.vue"),
      },
      {
        path: "/fund-config", //banner
        name: "FundConfig",
        meta: {
          title: "资金配置",
        },
        component: () => import("../views/general/fund-config/index"),
      },
      {
        path: "/display-zone", //显示专区配置
        name: "DisplayZone",
        meta: {
          title: "显示专区",
        },
        component: () => import("../views/general/display-zone/index"),
      },
      {
        name: "GeneralGoodsOperation",
        path: "/general/base-config/goods-operation",
        meta: {
          title: "运营配置",
        },
        component: () =>
          import("@/views/general/base-config/goods-operation/index.vue"),
      },
      // {
      //   path: '/supplier-reconciliation-manage', // 总控-财务管理-供货商对账管理
      //   name: 'SupplierReconciliationManage',
      //   meta: {
      //     title: '供货商对账管理',
      //   },
      //   component: () => import('../views/general/supplier-reconciliation-manage/index.vue')
      // },
      // {
      //   path: '/supplier-reconciliation-manage/details', // 总控-财务管理-供货商对账管理-供货单结算详情
      //   name: 'SupplierReconciliationDetails',
      //   meta: {
      //     title: '供货单结算详情',
      //   },
      //   component: () => import('../views/general/supplier-reconciliation-manage/page/details/index.vue')
      // },
      {
        path: "/city-reconciliation-manage", // 总控-财务管理-城市仓对账列表
        name: "CityReconciliationManage",
        meta: {
          title: "城市仓对账列表",
        },
        component: () =>
          import("../views/general/city-reconciliation-manage/index.vue"),
      },
      // {
      //   path: "/city-reconciliation-manage/details", // 总控-财务管理-城市仓结算详情
      //   name: "CityReconciliationDetails",
      //   meta: {
      //     title: "城市仓结算详情",
      //   },
      //   component: () =>
      //     import(
      //       "../views/general/city-reconciliation-manage/page/details/index.vue"
      //     ),
      // },
      {
        path: "/banner", //banner
        name: "banner",
        path: "/logistics/tag-print",
        name: "LogisticsTagPrint",
        meta: {
          title: "标签打印",
        },
        component: () => import("../views/logistics/tag-print/index"),
      },
      {
        path: "/logistics/tag-print-log",
        name: "LogisticsTagPrintLog",
        meta: {
          title: "标签打印记录",
        },
        component: () => import("../views/logistics/tag-print-log/index"),
      },
      {
        path: "/logistics/supply-print-log",
        name: "LogisticsSupplyPrintLog",
        meta: {
          title: "供货单打印记录",
        },
        component: () => import("../views/logistics/supply-print-log/index"),
      },
      {
        path: "/logistics/quality-control-input",
        name: "QualityControlInput",
        meta: {
          title: "品控录入",
        },
        component: () =>
          import("../views/logistics/quality-control-input/index"),
      },
      {
        path: "/logistics/sku-search-log",
        name: "LogisticsSkuSearchLog",
        meta: {
          title: "货号搜索",
        },
        component: () => import("../views/logistics/sku-search-log/index"),
      },
      {
        path: "/city-reconciliation-manage/details", // 总控-财务管理-城市仓结算详情
        name: "CityReconciliationDetails",
        meta: {
          title: "城市仓结算详情",
        },
        component: () =>
          import(
            "../views/general/city-reconciliation-manage/page/details/index.vue"
          ),
      },
      // {
      //   path: "/banner", //banner
      //   name: "banner",
      //   meta: {
      //     title: "banner列表",
      //   },
      //   component: () => import("../views/general/banner.vue"),
      // },
      // {
      //   path: "/fund-config", //banner
      //   name: "FundConfig",
      //   meta: {
      //     title: "资金配置",
      //   },
      //   component: () => import("../views/general/fund-config/index"),
      // },
      // {
      //   path: "/display-zone", //显示专区配置
      //   name: "DisplayZone",
      //   meta: {
      //     title: "显示专区",
      //   },
      //   component: () => import("../views/general/display-zone/index"),
      // },
      {
        path: "/zone-config", //显示专区配置
        name: "ZoneConfig",
        meta: {
          title: "专区配置",
        },
        component: () => import("../views/general/zone-config/index"),
      },
      {
        path: "/association", //关联商品
        name: "Association",
        meta: {
          title: "关联商品",
        },
        component: () => import("../views/general/association/index"),
      },
      {
        path: "/couponList", //优惠券管理
        name: "CouponList",
        meta: {
          title: "优惠券管理",
        },
        component: () => import("../views/general/couponList/index"),
      },
      {
        path: "/coupon-distribution-record", //优惠券发放记录
        name: "CouponDistributionRecord",
        meta: {
          title: "优惠券发放列表",
        },
        component: () =>
          import("../views/general/coupon-distribution-record/index"),
      },
      {
        path: "/warehouse-zone", //仓主专区
        name: "WarehouseZone",
        meta: {
          title: "仓主专区",
        },
        component: () => import("../views/general/warehouse-zone/index.vue"),
      },
      {
        path: "/warehouse-goods", //仓主专区商品
        name: "WarehouseGoods",
        meta: {
          title: "仓主专区商品",
        },
        component: () => import("../views/general/warehouse-goods/index.vue"),
      },
      {
        path: "/warehouse-tonnage", //仓主吨位设置
        name: "warehouseTonnage",
        meta: {
          title: "仓主吨位设置",
        },
        component: () => import("../views/general/warehouse-tonnage/index.vue"),
      },
      {
        path: "/SheetPrint",
        name: "SheetPrint",
        meta: {
          title: "装车单打印",
        },
        component: () => import("../views/logistics/sheet-print/index"),
      },
      {
        path: "/self-help",
        name: "self-help",
        meta: {
          title: "装车单自助打印",
        },
        component: () => import("../views/logistics/sheet-print/self-help.vue"),
      },
      {
        path: "/ParkingSpace",
        name: "ParkingSpace",
        meta: {
          title: "车辆排位图",
        },
        component: () => import("../views/logistics/parking-space/index"),
      },
      {
        path: "/ParkingSpaceNew",
        name: "ParkingSpaceNew",
        meta: {
          title: "车辆排位图",
        },
        component: () =>
          import("../views/logistics/parking-space/ParkingSpaceNew.vue"),
      },
      {
        path: "/self-procurement-register",
        name: "self-procurement-register",
        meta: {
          title: "自采登记",
        },
        component: () =>
          import("../views/logistics/self-procurement-register/index"),
      },
      {
        path: "/self-review",
        name: "self-review",
        meta: {
          title: "自采品复核",
        },
        component: () => import("../views/logistics/self-review/index"),
      },
      {
        path: "/online-self-procurement",
        name: "online-self-procurement",
        meta: {
          title: "线上零担",
        },
        component: () =>
          import("../views/logistics/online-self-procurement/index"),
      },
      {
        path: "/zero-carry-review",
        name: "zero-carry-review",
        meta: {
          title: "零担复核",
        },
        component: () => import("../views/logistics/zero-carry-review/index"),
      },
      {
        path: "/SheetToReview",
        name: "SheetToReview",
        meta: {
          title: "装车单复核",
        },
        component: () => import("../views/logistics/sheet-to-review/index"),
      },
      {
        path: "/sheet-out-stock",
        // path: '/SheetToReview',
        name: "SheetOutStock",
        meta: {
          title: "发车缺货",
        },
        component: () => import("../views/logistics/sheet-out-stock/index"),
      },
      {
        path: "/ToReviewLog",
        name: "ToReviewLog",
        meta: {
          title: "品控复核记录",
        },
        component: () => import("../views/logistics/to-review-log/index"),
      },
      {
        path: "/MoneyToReview",
        name: "MoneyToReview",
        meta: {
          title: "复核退款列表",
        },
        component: () => import("../views/general/money-to-review/index"),
      },
      {
        path: "/general/operation-center/qc-stockout-manage",
        name: "ReviewStockoutManage",
        meta: {
          title: "复核缺货管理",
        },
        component: () =>
          import(
            "../views/general/operation-center/qc-stockout-manage/page/index.vue"
          ),
      },
      {
        path: "/general/operation-center/city-deduction-manage",
        name: "CityDeductionManage",
        meta: {
          title: "城市仓扣款管理",
        },
        component: () =>
          import(
            "../views/general/operation-center/city-deduction-manage/page/index.vue"
          ),
      },
      {
        path: "/general/operation-center/supplier-deduction-manage",
        name: "SupplierDeductionManage",
        meta: {
          title: "供应商扣款管理",
        },
        component: () =>
          import(
            "../views/general/operation-center/supplier-deduction-manage/page/index.vue"
          ),
      },
      {
        path: "/trainManage", //发车管理
        name: "trainManage",
        meta: {
          title: "发车管理",
        },
        component: () => import("../views/logistics/trainManage.vue"),
      },
      {
        path: "/logdetails", //装车单详情
        name: "logdetails",
        meta: {
          title: "装车单详情",
        },
        component: () => import("../views/logistics/logdetails.vue"),
      },
      {
        path: "/logOrderList", //订单管理
        name: "logOrderList",
        meta: {
          title: "订单管理",
        },
        component: () => import("../views/logistics/orderlist.vue"),
      },
      {
        path: "/orderManage", //订货管理
        name: "orderManage",
        meta: {
          title: "订货管理",
        },
        component: () => import("../views/general/orderManage.vue"),
      },
      {
        path: "/logOrderDetail", //订单详情
        name: "logOrderDetail",
        meta: {
          title: "订单详情",
        },
        component: () => import("../views/logistics/orderDetail.vue"),
      },
      {
        path: "/qualityManage", //品控员管理
        name: "qualityManage",
        meta: {
          title: "品控员管理",
        },
        component: () => import("../views/logistics/qualityManage.vue"),
      },
      {
        path: "/stevedoring-account", //装卸账号
        name: "stevedoring-account",
        meta: {
          title: "装卸账号",
        },
        component: () =>
          import("../views/logistics/stevedoring/stevedoring-account.vue"),
      },
      {
        path: "/departure-situation", //发车情况
        name: "departure-situation",
        meta: {
          title: "发车情况",
        },
        component: () =>
          import("../views/logistics/stevedoring/departure-situation.vue"),
      },
      {
        path: "/vehicle-provision-report", //车辆预提报
        name: "vehicle-provision-report",
        meta: {
          title: "车辆预提报",
        },
        component: () =>
          import("../views/logistics/stevedoring/vehicle-provision-report.vue"),
      },
      {
        path: "/stevedoring-administration", //装卸队管理
        name: "stevedoring-administration",
        meta: {
          title: "装卸队管理",
        },
        component: () =>
          import(
            "../views/logistics/stevedoring/stevedoring-administration.vue"
          ),
      },
      //自提点
      {
        path: "/Receiving", //接货单管理
        name: "Receiving",
        meta: {
          title: "接货单管理",
        },
        component: () => import("../views/delivery/Receiving.vue"),
      },
      {
        path: "/Receivingdetails", //接货单管理详情
        name: "Receivingdetails",
        meta: {
          title: "接货单管理",
        },
        component: () => import("../views/delivery/Receivingdetails.vue"),
      },
      {
        path: "/ReceivingGoodsList", //分货单打印
        name: "ReceivingGoodsList",
        meta: {
          title: "分货单打印",
        },
        component: () => import("../views/delivery/ReceivingGoodsList.vue"),
      },
      {
        path: "/ReceivingManage", //提货单管理
        name: "ReceivingManage",
        meta: {
          title: "提货单管理",
        },
        component: () => import("../views/delivery/ReceivingManage.vue"),
      },
      {
        path: "/ReceivingPrint", //提货单打印
        name: "ReceivingPrint",
        meta: {
          title: "提货单打印",
        },
        component: () => import("../views/delivery/ReceivingPrint.vue"),
      },
      {
        path: "/CustomPitLocation", //提货单打印
        name: "CustomPitLocation",
        meta: {
          title: "自定义坑位号",
        },
        component: () =>
          import("../views/delivery/CustomPitLocation/index.vue"),
      },
      {
        path: "/ReceivingCustomerDetail", //客户详情
        name: "ReceivingCustomerDetail",
        meta: {
          title: "客户详情",
        },
        component: () =>
          import("../views/delivery/ReceivingCustomerDetail.vue"),
      },
      {
        path: "/ReceivingGoodsDetail", //订单详情
        name: "ReceivingGoodsDetail",
        meta: {
          title: "订单详情",
        },
        component: () => import("../views/delivery/ReceivingGoodsDetail.vue"),
      },
      {
        path: "/Receivingprintdetails", //提货单打印详情
        name: "Receivingprintdetails",
        meta: {
          title: "提货单打印详情",
        },
        component: () => import("../views/delivery/Receivingprintdetails.vue"),
      },
      {
        path: "/ReceivingWriteoff", //提货单核销
        name: "ReceivingWriteoff",
        meta: {
          title: "提货单核销",
        },
        component: () => import("../views/delivery/ReceivingWriteoff.vue"),
      },
      {
        path: "/ReceivingOrder", //提货单订单管理
        name: "ReceivingOrder",
        meta: {
          title: "订单管理",
        },
        component: () => import("../views/delivery/ReceivingOrder.vue"),
      },
      {
        path: "/ReceivingOrderDetail", //提货单订单查看详情
        name: "ReceivingOrderDetail",
        meta: {
          title: "查看详情",
        },
        component: () => import("../views/delivery/ReceivingOrderDetail.vue"),
      },
      {
        path: "/WeChatUrbanwarehouse", //城市仓小程序管理
        name: "WeChatUrbanwarehouse",
        meta: {
          title: "小程序账号管理",
        },
        component: () =>
          import("../views/Urbanwarehouse/accountmanagement.vue"),
      },
      {
        path: "/ToFollowCart", //跟车单打印
        name: "ToFollowCart",
        meta: {
          title: "跟车单打印",
        },
        component: () =>
          import("../views/Urbanwarehouse/to-review-log/index.vue"),
      },
      {
        path: "/export-center", // 总控-统计分析-下载中心
        name: "ExportCenter",
        meta: {
          title: "下载中心",
        },
        component: () => import("../views/statistic/export-center/index"),
      },
      {
        path: "/file-upload-center", // 总控-文件上传中心
        name: "FileUploadCenter",
        meta: {
          title: "文件上传中心",
        },
        component: () => import("../views/statistic/file-upload-center/index"),
      },
      {
        path: "/statistic/order-count", // 总控-统计分析-订单统计
        name: "StatisticOrderCount",
        meta: {
          title: "订单统计",
        },
        component: () => import("../views/statistic/order-count/index"),
      },
      {
        path: "/statistic/city-store", // 总控-统计分析-城市仓
        name: "StatisticCityStore",
        meta: {
          title: "城市仓",
        },
        component: () => import("../views/statistic/city-store/index"),
      },
      {
        path: "/statistic/buyer", // 总控-统计分析-采购
        name: "StatisticBuyer",
        meta: {
          title: "采购",
        },
        component: () => import("../views/statistic/buyer/index"),
      },
      {
        path: "/statistic/supplier", // 总控-统计分析-供应商
        name: "StatisticSupplier",
        meta: {
          title: "供应商",
        },
        component: () => import("../views/statistic/supplier/index"),
      },
      {
        path: "/statistic/purchase-after-sale", // 总控-统计分析-售后-采购
        name: "StatisticPurchaseAfterSale",
        meta: {
          title: "售后-采购",
        },
        component: () => import("../views/statistic/purchase-after-sale/index"),
      },
      {
        path: "/statistic/city-store-after-sale", // 总控-统计分析-售后-城市仓
        name: "StatisticCityStoreAfterSale",
        meta: {
          title: "售后-城市仓",
        },
        component: () =>
          import("../views/statistic/city-store-after-sale/index"),
      },
      {
        path: "/statistic/supplier-after-sale", // 总控-统计分析-售后-供应商
        name: "StatisticSupplierAfterSale",
        meta: {
          title: "售后-供应商",
        },
        component: () => import("../views/statistic/supplier-after-sale/index"),
      },
      {
        path: "/statistic/category-after-sale", // 总控-统计分析-售后-品类
        name: "StatisticCategoryAfterSale",
        meta: {
          title: "售后-品类",
        },
        component: () => import("../views/statistic/category-after-sale/index"),
      },
      {
        path: "/config/member-level-config", // 总控-基础设置-会员等级配置
        name: "MemberLevelConfig",
        meta: {
          title: "会员等级配置",
        },
        component: () => import("../views/general/member-level-config/index"),
      },
      {
        path: "/config/member-top-up-list", // 总控-会员管理-会员充值记录
        name: "MemberTopUpList",
        meta: {
          title: "会员充值记录",
        },
        component: () => import("../views/system/member-top-up-list/index"),
      },
      {
        path: "/config/corporate-recharge-list", // 总控-会员管理-对公客户充值记录
        name: "CorporateRechargeList",
        meta: {
          title: "对公客户充值记录",
        },
        component: () =>
          import("../views/system/corporate-recharge-list/index"),
      },
      {
        path: "/delivery-manage",
        name: "DeliveryManage",
        meta: {
          title: "自提点列表",
        },
        component: () =>
          import("../views/logistics/delivery-manage/page/index"),
      },
      {
        path: "/tdc-manage/account/details",
        name: "TdcAccountDetails",
        meta: {
          title: "结算详情",
        },
        component: () =>
          import("../views/logistics/tdc-manage/account/details/index"),
      },
      {
        path: "/tdc-manage/account/list",
        name: "TdcAccountList",
        meta: {
          title: "结算列表",
        },
        component: () =>
          import("../views/logistics/tdc-manage/account/list/index"),
      },
      {
        path: "/decoration",
        name: "decoration",
        meta: {
          title: "活动模版",
        },
        component: () =>
          import("../views/decoration/index"),
      },
      {
        path: "/decorationlist",
        name: "decorationlist",
        meta: {
          title: "活动模版",
        },
        component: () =>
          import("../views/decoration/decorationlist"),
      },
    ],
  },
];

const router = new VueRouter({
  // base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  console.log(store);
  document.title = `${to.meta.title}`;
  // const IP = sessionStorage.getItem('ip')
  // var ipList = ['222.128.101.9', '182.48.114.141', '163.16.50.159']
  // to.meta.isip = ipList.includes(IP)
  // console.log(to, '路由拦截')
  // if(sessionStorage.getItem("token")||sessionStorage.getItem("token")!=''){
  //   next();
  // }else{
  //   next('/');
  // }
  // console.log(sessionStorage.getItem("token"),'腊八节')
  // if (to.meta.isip) {
  if (to.path == "/index") {
    // console.log(to.path, '路1由拦截')
    next();
  } else {
    if (!sessionStorage.getItem("token") && to.path !== "/") {
      next("/");
    } else {
      // console.log('🆒 router.beforeEach', to);
      next();
    }
    // next();
  }
});
export default router;
