import { BASE } from './index';
import axios from './http';
const SalesP = {
    // 销售列表
    salelist: params => axios.post(`${BASE.PRO4}/sale/list`, params ),
    // 销售列表新增编辑
    salestore: params => axios.post(`${BASE.PRO4}/sale/store`, params ),
    // 销售列表下拉
    saleselectlist: params => axios.post(`${BASE.PRO4}/sale/select/list`, params ),
    // 销售销售绑定用户
    salesbindmember: params => axios.post(`${BASE.PRO4}/sale/bind/member`, params ),
    
}
export default SalesP;
