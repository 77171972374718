import { BASE } from "./index";
import axios from "./http";
const base = BASE.PRO1;
const base4 = BASE.PRO4;

/**
 * 总控-分组导出
 * @param {*} params
 * @returns
 */
export function postExportservicesteam(params = {}) {
  return axios({
    method: "GET",
    url: `${base}/clientele/services/team/export`,
    responseType: "blob",
    data: params,
  });
}
/**
 * 总控-推广列表导出
 * @param {*} params
 * @returns
 */
export function postExportgroupdata(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/share/member/group/data/export`,
    responseType: "blob",
    data: params,
  });
}
/**城市仓-回访记录导出
 * @param {*} params
 * @returns
 */
export function postExportmember_follow_record(params = {}) {
  return axios({
    method: "POST",
    url: `${base4}/member/follow_record/export`,
    responseType: "blob",
    data: params,
  });
}
/**城市仓-回访记录列表导出
 * @param {*} params
 * @returns
 */
export function postExportfollow_record(params = {}) {
  return axios({
    method: "POST",
    url: `${base4}/follow_record/export`,
    responseType: "blob",
    data: params,
  });
}
/**
 * 总控-推广详情导出
 * @param {*} params
 * @returns
 */
export function postExportgroupdetaildata(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/share/member/group/detail/export`,
    responseType: "blob",
    data: params,
  });
}
/**
 * 城市仓-销售列表导出
 * @param {*} params
 * @returns
 */
export function postExportsalelistexport(params = {}) {
  return axios({
    method: "POST",
    url: `${base4}/sale/list/export`,
    responseType: "blob",
    data: params,
  });
}
