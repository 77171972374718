function time(methods, e) {
    if(methods){
        let date = new Date(methods); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        if ((methods+'').length == 10) {
            date = new Date(methods * 1000)
        } else {
            date = new Date(methods)
        }
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
        let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        if (e) {
            return Y + M + D + h + m
        } else {
            return Y + M + D + h + m + ':' + s
        }
        return methods;
    }else{
        return '—'
    }
    

}
function empty(methods) {
    if (methods === 0) {
        return methods;
    }
    else if (!methods) {
        return '—'
    } else {
        return methods;
    }

}
//导出
export default {
    time,//
    empty
}