import { BASE } from './index';
import axios from './http';
console.log('url_link', sessionStorage.getItem('manageIsdentity'))
let url_link = sessionStorage.getItem('manageIsdentity') == 'tdc' ? BASE.PRO4 : BASE.PRO3
const INDEX = {
  //小程序管理列表
  userlist: params => axios.get(`${BASE.PRO3}/applet/user/list`, { params }),
  //小程序管理添加
  usersave: params => axios.post(`${BASE.PRO3}/applet/user/save`, params),
  // 获取登录人菜单权限
  getRoleMenu: params => axios.get(`${url_link}/get/role/menu`, { params }),
  // 管理员
  getDeliveryUser: params => axios.get(`${url_link}/get/logistics/user`, { params }),
  saveDeliveryUser: params => axios.post(`${url_link}/save/logistics/user`, params),
  updateDeliveryUserStatus: params => axios.post(`${url_link}/logistics/user/status`, params),
  // 角色
  getRoleList: params => axios.get(`${url_link}/get/role/list`, { params }),
  saveRole: params => axios.post(`${url_link}/role/save`, params),
  saveRoleMenuIds: params => axios.post(`${url_link}/role/menuIds/save`, params),
  getAllMenu: params => axios.get(`${url_link}/get/menu/list`, { params }),


  // 接货单列表
  detailbatch: params => axios.post(`${url_link}/add/order/detail/batch`, params),
  orderlist: params => axios.post(`${url_link}/receive/order/list`, params),
  batchlist: params => axios.post(`${url_link}/take/order/batch/list`, params),
  batchmemberlist: params => axios.post(`${url_link}/take/order/batch/member/list`, params),
  batchmemberOrder: params => axios.post(`${url_link}/take/order/batch/member/order`, params),
  deliverylist: params => axios.get(`${BASE.PRO4}/delivery/list`, params),
  deliveryOrderlist: url_link == BASE.PRO4 ? params => axios.get(`${url_link}/order/list`, { params }) : params => axios.post(`${url_link}/delivery/order/list`, params),
  deliveryOrderInfo: url_link == BASE.PRO4 ? params => axios.post(`${url_link}/order/info`, params) : params => axios.post(`${url_link}/delivery/order/info`, params),
  // 接货单详情
  orderdetail: params => axios.post(`${url_link}/receive/order/detail`, params),
  // 接货单详情打印
  orderdetailprint: params => axios.post(`${url_link}/receive/order/detail/print`, params),
  // 提货单打印
  orderlistti: url_link == BASE.PRO4 ? params => axios.post(`${url_link}/take/order/list`, params) : params => axios.post(`${url_link}/order/list`, params),
  // 分货单打印
  goodslistti: params => axios.post(`${url_link}/divide/delivery/take/order/list`, params),
  // 提货单打印打印列表批量
  orderlisttiprint: url_link == BASE.PRO4 ? params => axios.get(`${url_link}/take/order/print`, {params}) : params => axios.post(`${url_link}/order/print`, params),
  // 提货单打印详情
  orderdetailti: url_link == BASE.PRO4 ? params => axios.post(`${url_link}/take/order/detail`, params) : params => axios.post(`${url_link}/order/detail`, params),
  // 提货单核销
  tihuodanhexiao: url_link == BASE.PRO4 ? params => axios.post(`${url_link}/tihuodanhexiao`, params) : params => axios.post(`${url_link}/tihuodanhexiao`, params),
  // 点击核销
  ordercomplete: params => axios.post(`${url_link}/order/complete`, params),
  updatePayPassword: params => axios.post(`${url_link}/modify/password`, params),
  aftersaleList: params => axios.post(`${BASE.PRO3}/aftersale/un/list`, params),
  aftersaleEdList: params => axios.post(`${BASE.PRO3}/aftersale/ed/list`, params),
  aftersaleDetail: params => axios.post(`${BASE.PRO3}/aftersale/detail`, params),
};

export default INDEX;
